<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="success" size="small" @click="addDevice">添加模板</el-button>
            </el-form-item>
            <el-form-item label="" style="margin-left: 2vw">
              <el-input v-model="searchName" placeholder="请输入模板名称"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
      </div>
    </div>
      <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 1vh;">
        <el-table-column prop="id" label="序号" align="center"></el-table-column>
        <el-table-column prop="name" label="模板名称" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="templates" label="传感器" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="400">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="success" size="small">编辑</el-button>
            <el-button @click="del(scope.row)" type="success" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block pagingBox">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>

    <!-- 添加修改 -->
    <el-dialog title="" :visible.sync="messageBox" width="55vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
      </div>
      <div class="addAlaForm">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="10vw">
          <el-form-item label="模板名称" prop="name">
            <el-input v-model="addForm.name" placeholder="请输入模板名称"></el-input>
          </el-form-item>
          <el-form-item label="选择传感器" prop="templates">
            <el-input v-model="addForm.templates" placeholder="" @click.native="selectSensor"></el-input>
          </el-form-item>
        </el-form>
        <div style="width: 100%;height: 4vh;border-bottom: 1px solid #e3e3e3;font-size: 1.5vh;font-weight: bold">已选择
        </div>
        <div class="tableboxs">
          <el-table
              ref="multipleTable"
              :data="tableData2"
              tooltip-effect="dark"
              style="width: 100%; margin-top: 1vh;">
            <el-table-column prop="id" label="ID" align="center"></el-table-column>
            <el-table-column prop="name" label="名称" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="unit" label="单位" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="coeffcient" label="系数" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="200">
              <template slot-scope="scope">
                <el-button @click="up(scope.row)" type="success" size="mini" class="btn">上移</el-button>
                <el-button @click="down(scope.row)" type="success" size="small" class="btn">下移</el-button>
                <el-button @click="delSensor(scope.row)" type="success" size="small" class="btn">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block pagingBox">
            <el-pagination
                @size-change="handleSizeChanges"
                @current-change="handleCurrentChanges"
                :current-page="pages"
                :page-sizes="[1000, 2000]"
                :page-size="sizes"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totals"
            >
            </el-pagination>
          </div>
        </div>
        <div slot="footer" class="dialog-footer" v-show="showFlag">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!--传感器弹窗-->
    <el-dialog :visible.sync="sensorMessageBox" class="sensorBox">
      <el-button type="success" size="small" class="bttons" @click="selects()">选择</el-button>
      <div class="boxs">
        <el-table
            ref="multipleTable"
            :data="tableData3.filter(data => !searchKey || data.name.toLowerCase().includes(searchKey.toLowerCase()))"
            style="width: 100%; height: 55vh;">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column
              label="ID"
              prop="id">
          </el-table-column>
          <el-table-column
              label="名称"
              prop="name">
          </el-table-column>
          <el-table-column
              label="单位"
              prop="unit">
          </el-table-column>
          <el-table-column
              label="系数"
              prop="coeffcient">
          </el-table-column>
          <el-table-column
              align="right" width="200">
            <template slot="header" slot-scope="scope">
              <el-input
                  v-model="searchKey"
                  size="mini"
                  placeholder="输入关键字搜索"/>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'templateManagement',
  data() {
    return {
      tableData: [],
      tableData2: [],
      tableData3: [],
      page: 1,
      size: 10,
      total: 0,
      pages: 1,
      sizes: 1000,
      totals: 0,
      loading: false,
      formTitle: "",
      messageBox: false,
      showFlag: false,
      radio: '1',
      ids: 0,
      searchName: '',
      addForm: {templates:[]},
      rules: {
        name: [{required: true, message: "设备名称不可为空", trigger: ["blur", "change"]}],
      },
      showData: [],
      formTitles: '',
      searchKey: '',
      sensorMessageBox: false,
      arr: [],
      indexList:[],
    };
  },
  mounted() {
    this.getList();
     this.getSensorList()
  },
  methods: {
    //请求列表数据
    getList() {
      this.$get("/sensorTemplate/getTempList", {
        page: this.page,
        limit: this.size,
        name: this.searchName,
      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.datas;
          this.total = res.data.count;
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    //确认提交
    saveForm(formName) {
      let url;
      let flag;
      let id = this.ids;
      if (this.formTitle == '新增') {
        url = '/sensorTemplate/saveTemp';
        flag = "add"
      } else if (this.formTitle == '编辑') {
        url = '/sensorTemplate/saveTemp';
        flag = "edit"
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(url, {
            name:this.addForm.name,
            flag:flag,
            templates:this.indexList.toString(),
            id:id
          }).then((res) => {
            if (res.data.state == 'success') {
              this.selectSensor();
              this.$message.success('操作成功')
              this.messageBox = false
              this.sensorMessageBox = false;
              this.getList();
              this.searchName='';
              this.search()

            }
            else {
              this.$message.error(res.data.msg)
              this.messageBox = false
            }
          })
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增
    addDevice() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;
      this.tableData2 = [];
      // this.templates=[]
      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.addForm.resetFields();
      });
    },
    //编辑
    edit(item) {
      this.ids = item.id;
      this.formTitle = "编辑";
      this.messageBox = true;
      this.showFlag = true;
      this.$nextTick(() => {
        this.addForm = JSON.parse(JSON.stringify(item));
      });
      this.getEditSelSensors()
    },
    // 编辑时获取已选择传感器列表
    getEditSelSensors(){
      this.$get("/sensorTemplate/getTempSensorList", {
        page: this.pages,
        limit: this.sizes,
        id:this.ids
      }).then((res) => {
        //console.log(this.pages)
        //console.log(this.sizes)
        if (res.data.state == "success") {
          // this.tableData2 = this.arr;
          this.tableData2 = res.data.datas;
          this.totals = res.data.data.count;
          var index = 1;
          this.tableData2.forEach((i)=>{
            //console.log('i',i)
            i.index = index++;
          })

        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    // 获取传感器列表
    getSensorList() {
      this.$get('/sensorTemplate/getSensorList', {
        page: this.page1,
        limit: this.size1,
        keyword: this.searchKey
      }).then((res) => {
        if (res.data.state == "success") {
          //console.log('获取传感器列表', res.data);
          this.tableData3 = res.data.datas;

        }
      })
    },
    // 选择传感器调用
    selectSensor() {
      this.title = ''
      this.sensorMessageBox = true;
      // if (this.searchKey==""){
      //   //console.log("空了------")
      //   this.getSensorList()
      // }

    },
    // 传感器提交选择
    selects() {
      this.getSensorList()
      this.sensorMessageBox = false;
      // //console.log(  this.$refs.multipleTable.selection);
      this.arr = this.$refs.multipleTable.selection;
      //console.log(this.arr)
      let sensorList = [];
    this.indexList=[];
      this.arr.forEach((x) => {
        //console.log('x--',x)
        sensorList.push(x.name)
        this.indexList.push(x.id)
      })
      this.addForm.templates = sensorList
       // this.tableData2 = this.arr;
      // //console.log('sensorList-----', sensorList)
      // //console.log('indexList-----',  this.indexList)
      // //console.log('tableData2', this.tableData2)

    },

    up(item) {
      var index = item.index -1;
      if (item.index == 1) {
        //已经在顶部了，不能上移了
        return
      }
      this.$get('/sensorTemplate/moveUp', {
        tempId: this.ids,
        index: index,
      }).then((res) => {
        //console.log(res);
        if (res.status == 200) {
          //console.log('上移成功');
          this.getEditSelSensors()
          this.getList()
        }
      })
    },
    down(item) {
      var index = item.index-1;
      if (item.index >= this.tableData2.length) {
        //已经在最底部了，不能下移了
        return
      }
      this.$get('/sensorTemplate/moveDown', {
        tempId: this.ids,
        index: index,
      }).then((res) => {
        if (res.status == 200) {
          //console.log('下移成功');
          this.getEditSelSensors()
          this.getList()
        }
      })

    },
    // 已选择传感器列表的删除
    delSensor(item) {
      var index = item.index-1;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$get("/sensorTemplate/delete", {
          tempId: this.ids,
          index: index,
        }).then((res) => {
          if (res.data.state == "success") {
            this.$message.success("删除成功");
            this.getEditSelSensors()
            this.getList()
          } else {
            this.$message.info("删除失败");
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    //最外层删除
    del(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$post("/template/delTemplate", {
          id: item.id,
        }).then((res) => {
          if (res.data.state == "success") {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.info("删除失败");
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
// 搜索
    search() {
      //console.log('搜索呀-----')
      this.page = 1
      this.getList()
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList()
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList()
    },
    handleSizeChanges(val) {
      this.sizes = val;
      this.getEditSelSensors()
    },
    handleCurrentChanges(val) {
      this.pages = val;
      this.getEditSelSensors()
    },
    // // 传感器添加删除
    // handleEdit(index, row) {
    //   //console.log(index, row);
    // },
    // handleDelete(index, row) {
    //   //console.log(index, row);
    // },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

/* #content /deep/ .el-table::-webkit-scrollbar {
  display: none;
} */

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button, .el-button--info {
  margin-left: 10px !important;

}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .el-drawer {
  width: 36vw !important;
}

.icons {
  width: 30px !important;
}

.icons img {
  display: inline-block;
  width: 20%;
}

.btn, .sensorBox /deep/ .el-button {
  width: 2.5vw !important;
  height: 3vh !important;
  line-height: 3vh !important;
  text-align: center !important;
  font-size: 1vh !important;

}
.tableboxs /deep/ .el-button, .sensorBox /deep/ .el-button {
  padding: 0 !important;
}

.bttons {
  float: right;
}

.boxs {
  width: 100%;
  height: 60vh;
  overflow: hidden;
}
/*.tableBox{*/
/*  width: 100%;*/
/*  height: 70vh;*/
/*}*/


</style>

